.dropdown-toggle::after {
  content: none;
}
.dropdown-menu {
  border: none;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  padding: 0 !important;
  > li > a {
    color: #dd0030;
    padding: 11px 20px;
    display: block;
    white-space: normal !important;
  }
}
.dropdown-item {
  cursor: pointer;
  &:hover {
    color: #8d001f !important;
  }
  &:active {
    background-color: inherit !important;
    color: #dd0030 !important;
  }
}
.dropdown-menu-right {
  right: 0;
}

.menu-toggle {
  font-size: 18px;
  cursor: pointer;
}
