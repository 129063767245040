@import "../../assets/scss/variables.scss";

.table__container {
  height: 100%;
  max-width: 100%;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: #fff;
  transition: margin 0.5s ease-in-out, transform 0.5s ease-in-out;
  overflow-y: auto;
  &--with-setting {
    height: calc(100% - 60px);
  }
  &--disabled {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: #000;
      opacity: 0.6;
      z-index: 2;
    }
  }
  &--loading {
    position: relative;
    pointer-events: none;

    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: #fff;
      opacity: 0.6;
      z-index: 2;
    }
  }

  &--no--padding {
    padding: 0;
  }

  &--right-indent {
    margin-left: 306px;
  }
}

.table__header {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  color: #68605a;
  &--settings {
    margin-top: 10px;
  }
}

.table__title {
  margin: 0 24px 18px 0;
}

.table__block {
  padding: 20px 18px;
  display: flex;
  flex-direction: column;
  border-top: 3px solid #dd0030;
}
.settings__block {
  padding: 0 18px 20px 18px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 55px);
}

.table__toolbar {
  margin: 0 -10px;
  display: flex;
  flex-wrap: wrap;
}

.table__table {
  width: 100%;
  border-color: #e6e6ed;
  border-bottom: 1px solid #e6e6ed;

  th,
  td {
    font-size: 14px;
    padding: 11.2px;
    transition: background-color 0.3s;
    color: #212529;
    line-height: 21px;
    vertical-align: top;
    white-space: pre-wrap;
  }

  th {
    background-color: #f6f6f8;
    border-top: 1px solid #e6e6ed;
    border-bottom: 1px solid #e6e6ed;
    font-weight: bold;
    z-index: 1;
    white-space: nowrap;

    &.sortable-cell {
      cursor: pointer;

      &:hover {
        background-color: #e8e8ed;
      }

      .sortable-cell__indicator-up::after {
        content: "\f077";
        font-family: "Font Awesome 5 Free";
        margin-left: 8px;
        font-size: 12px;
        color: #dd0030;
      }

      .sortable-cell__indicator-down::after {
        content: "\f078";
        font-family: "Font Awesome 5 Free";
        margin-left: 8px;
        font-size: 12px;
        color: #dd0030;
      }
    }
  }

  tbody {
    tr {
      > td {
        padding: 0.7rem;
        display: table-cell;
        a {
          color: #dd0030 !important;
          margin-right: 0.5rem;
          cursor: pointer;
          i {
            width: 16px !important;
            height: 14px !important;
          }
        }
      }
      > .opened--list {
        tbody {
          tr {
            > td {
              border-bottom: 1px solid #eaeaed;
            }
          }
        }
      }
      &.tr__uncolored {
        background-color: #e6e6ed !important;
      }
      &:nth-child(2n) {
        background-color: #f6f6f8;
      }
      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }
}

.table__menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 0.5rem;
}

.table__wrapper {
  flex: 1;
  min-height: 0;
}

.table__wrapper__inner {
  width: 100%;
  overflow-x: auto;
  margin-bottom: 1rem;
}

.table__cell {
  &--bold {
    font-weight: 800;
    white-space: nowrap;
  }
}

.table__cell-button {
  padding: 0;
  margin: 0;
  cursor: pointer;
  color: inherit;
}
.btn-confirm {
  font-size: 12.4px !important;
  color: #dd0030;
  border: 1px solid #dbdbdb !important;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  i {
    margin-right: 2px;
  }
}
.tr__total {
  font-weight: bold;
  background-color: white !important;
  border-top: 1px solid #dee2e6;
}
.dash__wrapper__inner {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.dash_title {
  margin: 10px 0 -10px -5px;
  font-family: "Roboto Slab";
  font-weight: 300;
  :first-child {
    margin-top: 0;
    font-weight: inherit;
  }
  :nth-child(2n) {
    margin-top: 25px;
    font-weight: inherit;
  }
}
.h2_dash {
  color: #dd0030;
}
.breadcrumbs {
  padding: 0;
  background-color: transparent;
  font-size: 13px;
  line-height: 1.5;
  margin-bottom: 1px;
  margin-top: -14px;
  > a {
    color: #dd0030;
    text-decoration: none;
  }
  > a:hover {
    color: #8f001f;
  }
  > span {
    color: #999999;
    font-weight: bold;
  }
}
.active-tr {
  background-color: rgb(169, 255, 196) !important;
  button {
    background-color: rgb(63, 204, 108);
  }
}
.active-status {
  background-color: rgb(169, 255, 196);
  padding: 5px;
  border-radius: 5px;
  border: 1px solid rgb(63, 204, 108);
}
.inactive-status {
  background-color: rgb(255, 182, 169);
  padding: 5px;
  border-radius: 5px;
  border: 1px solid rgb(204, 91, 63);
}
.clock-span {
  margin: 0 5px;
}
.time-block {
  width: 100%;
  font-size: 16px;
  display: flex;
  flex-wrap: nowrap;
  margin-top: -10px;
  margin-bottom: 15px;
}

.support--history--image {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  border-color: inherit;
  margin-left: 5px;
  cursor: pointer;
}

.support--history--file {
  width: 40px;
  height: 40px;
  color: #dd0030;
  padding-left: 10px;
  cursor: pointer;
}
.support--history--image--modal {
  max-width: 100%;
  display: flex;
}
.modal-dialog--support {
  max-width: 50% !important;
  width: max-content !important;
  .modal-header {
    display: inline-flex;
  }
  .modal_body_history {
    padding: 0 !important;
  }
}

.image--filename {
  position: fixed;
  top: 1px;
  left: 10px;
  z-index: 2000;
  color: white;
  line-height: 1.5;
  font-weight: 300;
  font-size: 20px;
  font-family: "Roboto Slab";
}
.image--nav--icons {
  position: fixed;
  top: 10px;
  right: 0px;
  z-index: 2000;
  color: white;
  display: flex;
  width: max-content;
  svg {
    height: 30px;
    width: 30px;
    margin-right: 15px;
    cursor: pointer;
  }
}
