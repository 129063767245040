.form-control {
  position: relative;
  flex: 1 1 auto;
  height: 38px;
  padding: 6px 12px 6px 0 !important;
  margin-bottom: 0;
}
.form-control-input {
  width: 110%;
}
.input__settings {
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  > input {
    font-size: 25px;
    width: 110px;
    color: black;
    border: 1px solid rgb(214, 214, 214);
    padding: 0;
    text-align: center;
  }
}
