.btn-primary {
  color: #fff;
  background-color: $color-pink;
  border-color: $color-pink;

  &:hover,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  &.disabled, &:disabled {
    color: #fff;
    background-color: darken($color-pink, 10%);
    border-color: darken($color-pink, 10%);
  }

  &:focus, &.focus,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem lighten($color-pink, 45%);
  }

  &.disabled, &:disabled {
    opacity: 0.5;
  }
}

