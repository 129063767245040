.modal {
  display: block !important;
  background-color: rgba(0, 0, 0, 0.5);
  color: #68605a;
}
.modal-title {
  font-weight: 300;
  font-size: 24px;
  font-family: "Roboto Slab";
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  .form-control {
    padding-left: 0;
  }
}
label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 7px !important;
  font-weight: bold;
  font-size: 14px;
}
label .required-label {
  color: #dd0030;
  margin-left: 3px;
}
.form-password {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
}
.row-pass-small > div > small {
  font-size: 80%;
  font-weight: 400;
  font-size: 11.2px;
  line-height: 0.2 !important;
}
.change {
  color: #fff;
  background-color: #dd0025 !important;
  border: 1px solid #dd0025;
  margin-top: 9px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: 0.2s;
}
.change:hover {
  transition: 0.2s;
  background-color: #af0026 !important;
}
.btn-default:hover {
  background-color: #e0e0e0 !important;
}
.confirm-modal,
.delete-modal {
  max-width: 520px !important;
}
.table-in-modal {
  margin-top: -10px;
  margin-bottom: -17px;
}
