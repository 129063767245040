.checkbox {
  min-height: 18px;
  position: relative;
  margin-right: 5px;
  width: max-content;
  label {
    padding-left: 25px;
    display: inline-block;
    width: 100%;
    height: 100%;
    max-width: 120px;
    margin-bottom: 5px;
    font-size: 15px;
    flex-wrap: wrap;
    font-weight: 600;
    line-height: 1.5;
    &.wide {
      max-width: 100% !important;
    }
  }
  input {
    opacity: 0;
    position: absolute;
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  input + span {
    height: 18px;
    width: 18px;
    display: inline-block;
    border: 1px solid #dbdbdb;
    border-radius: 2px;
    position: absolute;
    left: 0;
    top: 1px;
    line-height: 14px;
    text-align: center;
    cursor: pointer;
    background-color: #fff;
  }
  input:checked + span {
    background-color: rgba(221, 0, 48, 0.1);
    border-color: #dd0030;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  input.disabled:checked + span {
    opacity: 0.3;
    cursor: not-allowed;
  }
  input.disabled:not(:checked) + span {
    cursor: not-allowed;
    background-color: rgba(0, 0, 0, 0.1);
  }
  input:checked + span::before {
    display: block;
    content: "\f00c";
    font-family: "Font Awesome 5 Free";
    margin-left: 2px;
    font-size: 16px;
    color: #dd0030;
  }
}
.label-type {
  flex-wrap: wrap;
  max-width: 90px;
}

.check-row {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 20px;
}

.all {
  margin-left: 10px;
  margin-right: 50px;
}

@media (max-width: 1800px) {
  .checkbox--group--item > label {
    max-width: 90px !important;
    word-wrap: break-word;
  }
}
