@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,100;1,300;1,400;1,500&display=swap");
@import "~bootstrap/scss/bootstrap.scss";
@import "./reset.scss";
@import "./variables.scss";
@import "./custom.scss";

body {
  margin: 0;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #68605a;
  background-color: #ededf2;
  overflow: hidden;
}

.page-content {
  padding: 15px;
  position: relative;
  margin-top: 61px;
  height: calc(100vh - 61px);
  overflow-y: auto;
  background-color: #ededf2;
}

.form-group {
  margin-bottom: 1rem;
  width: 100%;
}

.form-control {
  display: block;
  min-width: 0;
  width: 100%;
  height: 38px;
  text-indent: 0.75rem;
  font-size: 1rem;
  padding: 0;
  font-weight: 400;
  line-height: 1.5;
  color: $form-grey;
  background-color: $form-white;
  background-clip: padding-box;
  border: 1px solid $border-grey;
  border-radius: 0.25rem;
  color: #68605a;
  font-family: "Roboto";
}

.invalid-feedback {
  font-size: 11.2px !important;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: $invalid-red;
  text-align: left;
}

.page-title {
  font-size: 34px;
  font-family: "Roboto Slab", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
  line-height: 1.2;
  color: #68605a;
}
.invalid-visible {
  display: block !important;
}

[class*="col-"] {
  padding-right: 10px;
  padding-left: 10px;
}
.btn-default {
  height: 38px;
  color: $red;
  background-color: $form-white;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid #dbdbdb !important;
  padding: 6px 12px !important;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  &--black {
    color: #68605a;
    &:hover {
      color: inherit;
      background-color: inherit;
    }
  }
}
.placeholder {
  opacity: 0.5 !important;
  cursor: default;
}
.form-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  > .form-control,
  > .form-control-plaintext,
  > .input-group > .form-control,
  > .custom-select,
  > .custom-file {
    position: relative;
    font-family: "Roboto";
    flex: 1 1 auto;
    height: 38px;
    padding: 6px 24px 6px 0px !important;
    color: #68605a;
    min-width: 0;
    margin-bottom: 0;
    background-color: #fff;
  }
  > .custom-select {
    background: #fff
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      right 0.75rem center/8px 10px no-repeat;
  }
}
.form-group-transfer {
  display: block !important;
}
.btn-group {
  display: flex;
  width: 30%;
}
.btn-bump {
  padding: 5px;
  font-size: 32px;
  height: 170px;
  width: 190px;
}
.toolbar-with-search {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .toolbar-elements {
    width: 100%;
    display: flex;
  }
}
