@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700');
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:300,400');

// Global values
// --------------------------------------------------
$base-color:             #68605a;
$base-background-color:  #ededf2;
$base-font-family:       'Roboto', 'Helvetica Neue', Helvetica, SegoeUI, Arial, sans-serif;
$second-font-family:     'Roboto Slab', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$second-background-color: #fff;
$hover-background-color:  #f6f6f8;

// Colors
// --------------------------------------------------
$color-pink:             #dd0030;
$color-red:              #f66;
$color-purple:           #852b99;
$color-fuchsia:          #ff00ff;
$color-green:            #79a640;
$color-lime:             #00ff00;
$color-navy:             #1b2633;
$color-blue:             #3399ff;
$color-teal:             #008080;
$color-aqua:             #00ffff;
$color-yellow:           #ffc800;
$color-orange:           #ea7200;
$color-silver:           #ddd;
$color-white:            #fff;
$color-gray:             #999999;
$color-error:            #c93d44;
$color-inactive:         #afaba7;

// Forms
// --------------------------------------------------
$form-border-color:      #dbdbdb;
$table-border-color:     #e6e6ed;

$red: #dd0030;
$form-grey: #495057;
$form-white: #ffffff;
$border-grey: #ced4da;
$invalid-red: #dc3545;