.input-group-text{
    height: 38px;
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
}
.pink-color {
    color: #dd0030;
}
.form-control-search{
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
}
.form-control-search::placeholder{
    opacity: 0.5 !important;
}
.offset-lg-4{
    height: 38px;

}
.offset-lg-4 {
    margin-left: 40% !important;
    max-width: 60%;
}
@media (max-width: 768px) {
    .offset-lg-4 {
        max-width: 100%;
    }
}
.row-search{
    display: flex;
  }