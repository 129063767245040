.pagination-block {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  .page-size {
    width: 65px;
  }
}
.pagination {
  display: flex;
  justify-content: center;
  > ul > li > a {
    color: #dd0030 !important;
  }
  > ul > li.active > span {
    border-color: #dd0030 !important;
    background-color: #dd0030 !important;
    color: #fff !important;
  }
}
.page-item .page-link {
  cursor: pointer;
  padding: 8px 12px;
  font-size: 0.875rem;
  line-height: 1.5;
  text-decoration: none;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.custom-select {
  padding-left: 0 !important;
  background: #fff
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    right 0.75rem center/8px 10px no-repeat;
}
.float-sm-right {
  float: right;
}
.col-sm-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.col-sm-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
@media (max-width: 576px) {
  .col-sm-3,
  .col-sm-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
