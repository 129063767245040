.analytics--filters {
  display: flex;
  justify-content: space-between;
  .sort-icon--analytic {
    padding-left: 10px;
    height: 12px;
  }
}
.dropdown-toggle::after {
  display: none !important;
}
.filters-with-lines--container {
  padding-left: 30px;
  display: flex;
  width: calc(100% - 30px);
  position: relative;
  &::before {
    content: "";
    border-left: 1px solid #bebebe;
    width: 30px;
    height: calc(1rem + 19px);
    position: absolute;
    top: -1rem;
    left: 20px;
  }
  .filters-with-lines--item {
    padding: 0 10px;
    position: relative;
    min-width: 220px;
    &--without-width {
      min-width: fit-content;
    }
    &::before {
      content: "";
      border-bottom: 1px solid #bebebe;
      width: 20px;
      position: absolute;
      top: 19px;
      left: -10px;
    }
  }
  .restaurant--filter {
    width: 320px;
  }
}
.multiple-dropdown--container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  color: #68605a;
  height: 38px;
  min-width: 150px;
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  font-size: 1rem;
  overflow: hidden;
  background: #fff
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    right 0.75rem center/8px 10px no-repeat;
  .multiple-dropdown--container-inner {
    width: calc(100% - 35px);
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;
    height: 36px;
  }
  .hidden-elements-qty {
    font-size: 15px;
    padding: 0 2px;
    margin-right: 13.5px;
  }
  &-mini {
    min-width: 120px;
  }
  .multiple--placeholder {
    display: flex;
    width: calc(100% - 5px);
    justify-content: space-between;
    align-items: center;
  }
}
.multiple--filter-chosen--option {
  padding: 4px 7px;
  margin: 1px 2px;
  height: 32px;
  border-radius: 0.3rem;
  color: #dd0030;
  border: 1px solid #dd0030;
  display: flex;
  align-items: center;
  svg {
    margin-left: 2px;
    padding: 2px;
  }
}
.ul-multiple-dropdown {
  min-width: 300px !important;
  width: calc(100% - 20px);
}
.dropdown--multiple-filter {
  padding: 5px 0 5px 10px;
  width: 100%;
  cursor: pointer;
  &-input {
    border: 1px solid #dbdbdb;
    border-radius: 0.25rem;
    width: 96%;
    height: 30px;
    margin: 5px 2%;
    padding: 0px 5px;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  &.active {
    background-color: rgba(221, 0, 48, 0.2);
    &:hover {
      background-color: rgba(221, 0, 48, 0.3);
    }
  }
}
.filter-timers-cycle {
  display: flex;
  flex-wrap: nowrap;
  width: min(100%-450px, 70%);
}
@media (max-width: 1100px) {
  .filter-timers-cycle {
    width: 100%;
  }
}
