.sidebar-container {
  position: relative;
  width: 0;
  height: 0;
  overflow-x: visible;
  .sidebar {
    position: absolute;
    top: 0;
    left: 0;
    width: 302px;
    padding-right: 15px;
    transition: margin 0.5s ease-in-out, transform 0.5s ease-in-out;
    height: calc(98vh - 60px);
    overflow-y: hidden;
    nav {
      position: relative;
      height: max-content;
      max-height: calc(100% - 20px);
      overflow-y: hidden;
      scrollbar-width: auto !important;
      &:hover {
        overflow-y: auto;
      }
      ::-webkit-scrollbar {
        width: 5px;
        height: 7px;
        background-color: rgba(0, 0, 0, 0);
      }
      ::-webkit-scrollbar-track {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0);
      }
      ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: #a5a5a5;
      }
    }
  }
}
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: #ffffff;
}
::-webkit-scrollbar-track {
  border-radius: 6px;
  background-color: #f0f0f0;
}
::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #a5a5a5;
}
.sidebar-hidden {
  margin-left: -320px;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
  margin-top: 0;
}
.sidebar-menu > ul > li {
  padding: 0;
  &:not(:last-child) {
    margin: 0 0 3px;
  }
  > a {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-family: 'Roboto Slab', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    background-color: #fff;
    border-left: 3px solid transparent;
    padding: 13px 15px 13px 18px;
    color: #dd0030 !important;
    transition: 0.3s;
    text-decoration: none;
    [class^="fa"] {
      font-size: 19px;
      margin-right: 13px;
      width: 19px;
      text-align: center;
      vertical-align: middle;
      padding-bottom: 4px;
    }
    > span {
      display: inline-block;
      padding-bottom: 4px;
      line-height: 1.5;
      width: max-content;
      max-width: 200px;
      overflow: hidden;
      white-space: nowrap;
      word-wrap: break-word;
      text-overflow: ellipsis;
    }
  }
  a:not(.disabled) {
    cursor: pointer;
  }
  a.disabled {
    pointer-events: none;
    cursor: default;
    color: darkgrey !important;
    opacity: 0.4;
  }
  > a.current {
    border-color: #dd0030;
    > span {
      padding-bottom: 2px;
      border-bottom: 2px dotted;
    }
  }
}
.sidebar-menu > ul > li:hover > a:not(.disabled),
.sidebar--clickable-tooltip > ul > li:hover > a:not(.disabled) {
  transition: 0.3s;
  color: #8d001f !important;
}
.fa,
.fas,
.far,
.fal,
.fad,
.fab {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
.sidebar-footer {
  color: rgba(104, 96, 90, 0.4);
  font-size: 13px;
  text-align: center;
  padding: 10px 0;
}
.sidebar--clickable-tooltip {
  position: absolute;
  left: 295px;
  top: 0;
  z-index: 1000;
  opacity: 1;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0px 0px 3px 2px #a5a5a5;
  ul > li {
    min-width: max-content;
    cursor: pointer;
    width: 100%;
    height: max-content;
    a {
      padding: 8px 40px 8px 20px;
      display: block;
      font-size: 15px;
      font-family: 'Roboto Slab', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      color: #dd0030;
      text-decoration: none;
      transition: 0.3s;
      i {
        margin-right: 5px;
      }
      span {
        padding-bottom: 4px;
        line-height: 1.5;
      }
    }
    &:hover > a:not(.disabled) {
      transition: 0.3s;
      border-radius: 3px;
      color: #8d001f !important;
      background-color: #ececec;
    }
  }
}
