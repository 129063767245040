.buttonPanel {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out;
  padding: 6px 12px;
  .fa {
    padding-right: 5px;
  }
}
.btn-download {
  color: #fff;
  background-color: #dd0030;
  border-color: #dd0030;
}
.btn-new {
  color: #fff;
  background-color: #dd0030;
  border-color: #dd0030;
  opacity: 1;
}
.btn-new-big {
  font-size: 32px;
  margin: 20px;
  min-width: 125px;
}
.btn-metrics {
  color: #dd0030;
  border-color: rgb(182, 182, 182);
  margin-right: 10px;
}
.disabled-button {
  opacity: 0.5;
  cursor: default !important;
}
