.dropdown-width {
  width: max-content;
}
.colors--pie-chart {
  width: 14px;
  height: 14px;
  margin: auto 3px;
}
.analytics--wide-dropdown {
  min-width: 300px !important;
  width: max-content;
}
.table__toolbar--right {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin: 0 -10px;
}
.spinner-container {
  display: flex;
  width: 100%;
  height: 50%;
  justify-content: center;
  align-items: center;
}
.bar-chart--analytics {
  div > div > div > svg > g > g > g > g > text,
  div > div > div > svg > g > g > g > g > g > text {
    opacity: 0;
    animation-name: bar-chart-annotation;
    animation-duration: 0.1s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
  }
  @keyframes bar-chart-annotation {
    0% {
      opacity: 0;
    }
    99% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
.small-bar--chart {
  height: 130px;
  width: 100%;
  overflow-x: hidden;
  padding-bottom: 10px;
  &-error {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 20px;
  }
  .small-bar--loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    svg {
      height: 30px;
      width: 30px;
    }
  }
  .google-visualization-tooltip > ul > li {
    display: flex;
    width: max-content;
    height: 15px;
  }
}
.piechart--container {
  width: 50%;
  position: relative;
  .visible--chart {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 81%;
    & > div > div > div > svg > rect {
      fill: rgba(0, 0, 0, 0) !important;
    }
  }
  .one-pie--chart {
    & > div > div > div > svg > g > text {
      fill: rgba(0, 0, 0, 0) !important;
      font-size: 0;
    }
  }
  .invisible--chart {
    position: absolute;
    width: 100%;
    height: 81%;
    z-index: 1;
    animation-name: appear-invisible-chart;
    animation-duration: 0.5s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
  }
  .circle {
    display: inline-flex;
    overflow: hidden;
    position: absolute;
    top: 10px;
    width: 100%;
    height: 81%;
    z-index: 3;
    animation-name: hide-circle;
    animation-duration: 0.5s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
  }

  .circle__half {
    width: 100%;
    height: 81%;
    position: relative;
    overflow: hidden;
  }

  .circle__half:before {
    height: inherit;
    width: 102%;
    position: absolute;
    content: "";
    background-color: #fff;
    transform-origin: 100% 50%;
    transform: rotate(0deg);
    opacity: 1;
    animation-name: rotate-circle-half--right;
    animation-duration: 0.5s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
  }

  .circle__half--right {
    transform: scale(-1, 1);
    position: relative;
  }

  .circle .circle__half--right:before {
    animation-name: rotate-circle-half;
    animation-fill-mode: forwards;
  }
  @keyframes appear-invisible-chart {
    0% {
      opacity: 0;
    }
    99% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes hide-circle {
    0% {
      z-index: 3;
    }
    99% {
      z-index: 3;
    }
    100% {
      z-index: 0;
    }
  }

  @keyframes rotate-circle-half {
    0% {
      transform: rotate(360deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(180deg);
    }
  }

  @keyframes rotate-circle-half--right {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(180deg);
    }
  }
}
.chart-graphics--area {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .chart--container {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
  }
}
