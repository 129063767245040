.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
.nav-tabs, .nav-settings {
    .nav-item .nav-link{
        cursor: pointer;
        color: #dd0030 !important;
        margin-bottom: -1px;
        border: 1px solid transparent;
        font-size: 14px;
    }
    .nav-item.active .nav-link.active{
        background-color: #fff;
    }
}
.nav-tabs{
    border-bottom: 1px solid #dee2e6;
    .nav-item .nav-link {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
    }
    .nav-item.active .nav-link.active{
        color: #495057 !important;
        border-color: #dee2e6 #dee2e6 #fff !important;
    }
}
.nav-settings {
    background-color: #ededf2;
    .nav-item{ 
        border: 3px solid #ededf2;
        background-color: #fff;
        .nav-link{
            border-bottom-color: #fff;
            padding: 8px 25px;
            font-size: 16px;
            padding: 15px 20px;
            font-family: 'Roboto Slab';
            i{
                padding-right: 5px;
            }
        }
    }
    .nav-item:first-child{
        border-left: none;
    }
    .nav-item.active{
        border-bottom-color: #fff;
        .nav-link.active{
            color: #dd0030;
            border-top: 3px solid #dd0030;
            border-bottom-color: #fff;
        }
    }
}